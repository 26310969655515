import {
  FIELDS_ROLES,
  ROLE_DOWNLOAD_MESSAGE,
  ROLE_FORM,
  ROLE_MESSAGE,
  FIELDS,
} from '../constants/roles'
import * as _ from 'lodash'
import {
  escapeRegExp,
  innerText,
  isUploadButton,
  isCaptchaField,
  isTemplate,
  isPreviewMode,
  shouldSendData,
  getValidCollectionId,
  findPlugin,
} from './viewer-utils'
import { strategies } from './strategy/strategies'
import { submitUtils } from './submit-utils'
import { FormPlugin } from '../constants/plugins'
import { isPaymentAllowed } from './services/payment-services'
import { VIEWER_ORIGIN, EVENTS } from '../constants/bi-viewer'
import { CRM_TYPES } from '../constants/crm-types-tags'
import { siteStore } from './stores/site-store'
import Experiments from '@wix/wix-experiments'
import translations from './services/translations'
import { serializeError, getViewerSentryDSN, getAppVersion } from '../utils/utils'
import { RavenStatic } from 'raven-js'
import { registerBehaviors } from './behaviors'

const ERROR_COLOR = '#FF4040'

let initialized = false

const initRavenInstance = ({ platformServicesAPI, scopedGlobalSdkApis, dsn }): RavenStatic => {
  return platformServicesAPI.monitoring.createMonitor(dsn, event => {
    const errors = _.chain(_.get(event, 'exception.values'))
      .map(exception => (exception.message ? exception.message : exception.value))
      .compact()
      .value()

    event.fingerprint = errors.length > 0 ? [...errors] : ['{{ default }}']
    const metaSiteId = _.get(platformServicesAPI, 'bi.metaSiteId')

    if (metaSiteId) {
      event.tags['msid'] = metaSiteId
    }

    const artifactVersion = _.get(platformServicesAPI, 'bi.artifactVersion')

    if (artifactVersion) {
      event.tags['artifactVersion'] = artifactVersion
    }

    const appVersion = getAppVersion()
    const localEnvironment = appVersion === 'local-development'
    const isQAEnvironment = _.get(scopedGlobalSdkApis, 'location.query.isqa') === 'true'
    const environment = localEnvironment ? 'Dev' : isQAEnvironment ? 'QA' : 'Prod'

    event.environment = environment

    return event
  })
}

export const initAppForPage = async (
  initAppParams,
  platformApi,
  scopedGlobalSdkApis,
  platformServicesAPI
) => {
  if (initialized) {
    return Promise.resolve()
  }

  initialized = true

  const ravenInstance = initRavenInstance({
    platformServicesAPI,
    scopedGlobalSdkApis,
    dsn: getViewerSentryDSN(),
  })

  try {
    await siteStore.init({
      ravenInstance,
      initAppParams,
      platformApi,
      scopedGlobalSdkApis,
      platformServicesAPI,
      experiments: new Experiments({ baseUrl: 'https://www.wix.com' }),
      translationsFactory: translations,
    })
  } catch (error) {
    ravenInstance.captureException(error)
    ravenInstance.setTagsContext()
    ravenInstance.setExtraContext()

    throw error
  }

  return Promise.resolve()
}

const getFormName = $w => {
  const form = $w(`@${ROLE_FORM}`)

  return {
    form_comp_id: form.uniqueId,
    form_name: form.connectionConfig.formName,
    template: _.get(form.connectionConfig, 'preset', 'unknown'),
  }
}

const paymentStatusIsValid = status => ['Successful', 'Offline', 'Pending'].includes(status)

const getFormParamsForBi = ($w, fields, wixLocation) => ({
  num_of_attachments: getAttachmentsCount(fields),
  form_url: wixLocation.url || '',
  ...getFormName($w),
})

const logPublishSitePopupOpened = $w =>
  siteStore.log({
    evid: EVENTS.PUBLISH_SITE_PANEL_OPENED,
    form_comp_id: getFormName($w).form_comp_id,
    builderOrigin: VIEWER_ORIGIN,
  })

const getSubmitErrorParamsForBi = ({ $w, fields, wixLocation, reason, reason_body }) => ({
  reason,
  reason_body,
  ...getFormParamsForBi($w, fields, wixLocation),
})

const getAttachmentsCount = fields =>
  _.filter(fields, field => isUploadButton(field) && field.value.length > 0).length

const getFieldValidity = fields => {
  const valueMissing = 'valueMissing'
  const errorOrder = [
    valueMissing,
    'fileNotUploaded',
    'typeMismatch',
    'patternMismatch',
    'rangeOverflow',
    'rangeUnderflow',
    'stepMismatch',
    'tooLong',
    'tooShort',
    'badInput',
    'customError',
  ]
  let errorType = _.find(errorOrder, error => _.some(fields, `validity.${error}`))
  const field = _.find(fields, field => {
    if (isCaptchaField(field)) {
      const missingToken = _.isEmpty(field.token)

      if (missingToken) {
        errorType = valueMissing
      }

      return missingToken
    }

    return _.get(field, `validity.${errorType}`)
  })

  return `${errorType} : ${_.get(field, 'connectionConfig.fieldType')}`
}

const showFormError = (message, errorMessage) => {
  if (!_.get(message, 'html')) {
    return
  }
  const colorRegExp = /color: ?[^;"]+/
  let htmlErrorMessage = errorMessage
  if (message.html.indexOf(colorRegExp) === -1) {
    htmlErrorMessage = `<span style="color: ${ERROR_COLOR}">${htmlErrorMessage}</span>`
  }
  message.html = message.html
    .replace(colorRegExp, `color: ${ERROR_COLOR}`)
    .replace(new RegExp(`>${escapeRegExp(innerText(message.html))}`), `>${htmlErrorMessage}`)
  message.show()
}

const resetCrucialFields = $w => {
  const captchaField = $w(`@${FIELDS.ROLE_FIELD_RECAPTCHA}`)

  if (captchaField.length > 0) {
    captchaField.reset()
  }
}

const onSubmit = async (
  {
    $w,
    $message = {},
    wixLocation,
    wixWindow,
    wixSite,
    wixPay,
    isPaymentForm,
    getUploadFieldsData = null,
    fillFieldsValues = null,
    isMultiStepForm,
    getMultiStepFields,
    initialFields = [],
  },
  strategy
) => {
  let fields = []
  let $submitButton

  const postSubmitActions = (shouldShowSubmissionSuccess = true) => {
    if (shouldShowSubmissionSuccess) {
      submitUtils.resetFields(fields, initialFields)
      strategy.postSubmission()
    }

    if (!isTemplate(wixLocation)) {
      submitUtils.sendWixAnalytics({ wixSite, wixLocation, wixWindow })
    }
  }

  const form = $w(`@${ROLE_FORM}`)

  try {
    siteStore.log({
      evid: EVENTS.USER_CLICKS_SUBMIT,
      ...getFormParamsForBi($w, fields, wixLocation),
    })

    $submitButton = submitUtils.getSubmitButton($w)
    $submitButton.disable()

    if (isMultiStepForm) {
      fields = getMultiStepFields()
    } else {
      fields = submitUtils.getFields({ $w, roles: FIELDS_ROLES })
    }

    const invalidFields = submitUtils.validateFields({ fields, strategy })

    if (invalidFields.length !== 0) {
      siteStore.log({
        evid: EVENTS.SUBMISSION_FAILURE,
        ...getSubmitErrorParamsForBi({
          $w,
          fields,
          wixLocation,
          reason: 'field validity',
          reason_body: getFieldValidity(fields),
        }),
      })

      $submitButton.enable()

      return false
    }

    const shouldShowPublishSitePopupWhenInPreviewMode = async () => {
      return (
        isPreviewMode(wixWindow) &&
        isPaymentForm &&
        siteStore.initAppParams.url &&
        (await isPaymentAllowed())
      )
    }

    if (await shouldShowPublishSitePopupWhenInPreviewMode()) {
      logPublishSitePopupOpened($w)
      const publishSitePopupUrl = () =>
        siteStore.initAppParams.url
          .split('/')
          .slice(0, -1)
          .concat(['statics', `viewer-publish-site-panel.html`])
          .join('/')
      await wixWindow.openModal(
        `${publishSitePopupUrl()}?msid=${siteStore.platformServices.bi.metaSiteId}`,
        {
          width: 500,
          height: 247,
          theme: 'BARE',
        }
      )
      $submitButton.enable()
      return false
    }

    if (shouldSendData(wixLocation)) {
      let attachments = []
      let fieldsWithoutAttachments = fields

      if (isMultiStepForm) {
        fillFieldsValues(fields)
        attachments = getUploadFieldsData()
        fieldsWithoutAttachments = fields.filter(
          ({ uniqueId }) => !_.find(attachments, { uniqueId })
        )
      }

      attachments = [
        ...attachments,
        ...(await submitUtils.getAttachments(fieldsWithoutAttachments)),
        ...(await submitUtils.getSignatureAttachments({
          fields: fieldsWithoutAttachments,
          form,
        })),
      ]

      const serverResponse = await submitUtils.sendFieldsToServer({
        strategy,
        attachments,
        fields,
        viewMode: wixWindow.viewMode,
      })

      let shouldShowSuccessMessage = true

      const orderId = _.get(serverResponse, 'orderId')

      if (orderId) {
        const userInfo = getUserInfo(fields)
        const paymentResponse = await wixPay.startPayment(orderId, {
          userInfo,
          allowManualPayment: true,
        })
        if (!paymentStatusIsValid(paymentResponse.status)) {
          shouldShowSuccessMessage = false
        }
      }

      // this event should be after all server requests (wix forms + wix data)
      siteStore.log({
        evid: EVENTS.SUBMISSION_SUCCESS,
        ...getFormParamsForBi($w, fields, wixLocation),
      })

      postSubmitActions(shouldShowSuccessMessage)
      $submitButton.enable()
    } else {
      postSubmitActions()
      $submitButton.enable()
      return true
    }
  } catch (error) {
    const formMetadata = _.pick(_.get(form, 'connectionConfig'), ['formName', 'plugins', 'preset'])
    const preset = _.get(formMetadata, 'preset')

    siteStore.raven.captureException(error, {
      extra: {
        errorObject: serializeError(error),
        formMetadata,
        queryParams: _.get(siteStore.wixApi, 'location.query'),
      },
      ...(preset
        ? {
            tags: {
              preset,
            },
          }
        : {}),
    })
    siteStore.raven.setTagsContext()
    siteStore.raven.setExtraContext()

    if ($submitButton) {
      $submitButton.enable()
    }

    resetCrucialFields($w)
    showFormError($message, siteStore.t('submitFailed'))

    siteStore.log({
      evid: EVENTS.SUBMISSION_FAILURE,
      ...getSubmitErrorParamsForBi({
        $w,
        fields,
        wixLocation,
        reason: _.get(error, 'name', 'unknown reason'),
        reason_body: _.get(error, 'message', 'unknown message'),
      }),
    })
  }
}

const getUserInfo = fields => {
  const wantedCrmTypes = [
    CRM_TYPES.FIRST_NAME,
    CRM_TYPES.LAST_NAME,
    CRM_TYPES.PHONE,
    CRM_TYPES.EMAIL,
  ]

  const userInfo = fields.reduce((acc, field) => {
    const {
      connectionConfig: { crmType },
    } = field
    if (!_.isEmpty(field.value) && wantedCrmTypes.includes(crmType)) {
      acc[crmType] = field.value
    }
    return acc
  }, {})

  return userInfo
}

const registerSubmitButtonIfExists = ($w, submitArgs, strategyImp) => {
  const $submitButton = submitUtils.getSubmitButton($w)

  if (!$submitButton) {
    return
  }

  $submitButton.onClick(() => onSubmit(submitArgs, strategyImp))
}

const registerMultiStepForm = ($w, $multiStepForm, { wixLocation, strategy }) => {
  let onNavigationEnd = Promise.resolve()
  const previousButtons = submitUtils.getPreviousButtons($w)
  const nextButtons = submitUtils.getNextButtons($w)
  const thanksStep = submitUtils.getThanksStep($w)
  const fields = submitUtils.getFields({ $w, roles: FIELDS_ROLES })
  const {
    getCurrentFields,
    getAllFields,
    saveDatePickersInState,
    fillDatePickersInState,
    fillFieldsValues,
  } = submitUtils.registerFieldsToStates($multiStepForm, thanksStep, fields, onNavigationEnd)
  const { uploadFields, getUploadFieldsData } = submitUtils.registerFieldsToCache({
    form: $multiStepForm,
  })

  nextButtons.forEach(button => {
    button.onClick(() => {
      onNavigationEnd = submitUtils.navigateToNextStep(
        { $w, $multiStepForm, $nextButton: button },
        {
          onNavigationEnd,
          getCurrentFields,
          uploadFields,
          saveDatePickersInState,
          fillDatePickersInState,
          strategy,
          wixLocation,
        }
      )
    })
  })
  previousButtons.forEach(button => {
    button.onClick(() => {
      onNavigationEnd = submitUtils.navigateToPreviousStep(
        { $w, $multiStepForm, $previousButton: button },
        { saveDatePickersInState, fillDatePickersInState, onNavigationEnd }
      )
    })
  })
  return { getUploadFieldsData, fillFieldsValues, getAllFields }
}

const pageReadyImpl = ($w, payload) => {
  if (!$w(`@${ROLE_FORM}`).length) {
    return
  }

  siteStore.appLoadStarted()

  const {
    window: wixWindow,
    location: wixLocation,
    user: wixUsers,
    site: wixSite,
    pay: wixPay,
  } = payload

  const form = $w(`@${ROLE_FORM}`)
  const {
    collectionId,
    secondsToResetForm,
    successActionType,
    successLinkValue,
    successExternalLinkValue,
    submitOptionsUploadedObject,
  } = form.connectionConfig

  const plugins = _.get(form.connectionConfig, 'plugins')
  const paymentPlugin = findPlugin(plugins, FormPlugin.PAYMENT_FORM)
  const isPaymentForm = !!paymentPlugin && !!paymentPlugin.payload
  const isMultiStepForm = !!findPlugin(plugins, FormPlugin.MULTI_STEP_FORM)

  const formId = form.uniqueId
  const validCollectionId = getValidCollectionId(formId, collectionId)
  const $successMessage: any = $w(`@${ROLE_MESSAGE}`)
  const $downloadMessage: any = $w(`@${ROLE_DOWNLOAD_MESSAGE}`)
  let submitArgs: any = {
    $w,
    formId,
    collectionId: validCollectionId,
    secondsToResetForm,
    successActionType,
    successLinkValue,
    successExternalLinkValue,
    submitOptionsUploadedObject,
    wixLocation,
    wixWindow,
    wixUsers,
    wixSite,
    wixPay,
    isPaymentForm,
    isMultiStepForm,
    initialFields: submitUtils
      .getFields({ $w, roles: FIELDS_ROLES })
      .map(({ uniqueId, value }) => ({ uniqueId, value })),
  }

  if (_.get($successMessage, 'hide')) {
    $successMessage.hide()
    submitArgs = {
      ...submitArgs,
      $message: $successMessage,
    }
  }
  if (_.get($downloadMessage, 'hide')) {
    $downloadMessage.hide()
    submitArgs = {
      ...submitArgs,
      $message: $downloadMessage,
    }
  }
  const strategy = _.find(strategies, s => s.isEnabled($w))
  if (!strategy) {
    return
  }
  const strategyImp = new strategy(submitArgs)

  if (isMultiStepForm) {
    const { getUploadFieldsData, fillFieldsValues, getAllFields } = registerMultiStepForm(
      $w,
      form,
      {
        wixLocation,
        strategy: strategyImp,
      }
    )
    submitArgs = {
      ...submitArgs,
      getUploadFieldsData,
      fillFieldsValues,
      getMultiStepFields: getAllFields,
    }
  }

  registerSubmitButtonIfExists($w, submitArgs, strategyImp) // TODO: Move this one too
  registerBehaviors(submitArgs)

  strategyImp.registerCustomValidation(submitArgs)

  siteStore.appLoaded()
}

export const createControllers = controllerConfigs => {
  return controllerConfigs.map(() =>
    Promise.resolve({
      pageReady: siteStore.raven.wrap(pageReadyImpl),
    })
  )
}
